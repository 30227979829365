import React from 'react'
import { Col, Row } from 'react-bootstrap'
import RoomIcon from '@mui/icons-material/Room';
import EmailIcon from '@mui/icons-material/Email';
import { Link } from 'react-router-dom';
import CookieBanner from '../pages/auth/CookieBanner';


const Footer = () => {
    const currentYear = new Date().getFullYear()
    return (
        <section id='footer'>
            <div className="container">
                <Row>
                    <Col lg={3} md={6} sm={12} className="colm-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="245.588" height="47.505" viewBox="0 0 245.588 47.505">
                            <g id="Group_8417" data-name="Group 8417" transform="translate(-283.104 -515.508)">
                                <g id="IMAGE" transform="translate(345.704 516.562)">
                                    <g id="Group_8413" data-name="Group 8413">
                                        <text id="Online" transform="translate(0 14)" fill="#fff" fontSize="15" fontFamily="RubikRoman-Regular, Rubik" style={{ isolation: 'isolate' }}><tspan x="0" y="0">Online</tspan></text>
                                    </g>
                                </g>
                                <g id="IMAGE-2" data-name="IMAGE" transform="translate(344.351 532.535)">
                                    <g id="Group_8414" data-name="Group 8414">
                                        <text id="OCR" transform="translate(0 23)" fill="#36f" fontSize="25" fontFamily="RubikRoman-SemiBold, Rubik" fontWeight="600" style={{ isolation: 'isolate' }}><tspan x="0" y="0">OCR</tspan></text>
                                    </g>
                                </g>
                                <g id="IMAGE-3" data-name="IMAGE" transform="translate(399.692 531.013)">
                                    <text id="converter" transform="translate(0 25)" fill="#fff" fontSize="27" fontFamily="Rubik-Light, Rubik" fontWeight="300"><tspan x="0" y="0">converter</tspan></text>
                                </g>
                                <g id="Group_8416" data-name="Group 8416" transform="translate(283.104 515.508)">
                                    <path id="Path_13685" data-name="Path 13685" d="M285,523.33h-1.847v-7.783h7.784c0,.556.015,1.111-.018,1.663,0,.073-.252.188-.388.189-1.555.014-3.111,0-4.666.011-.994.006-.858-.14-.863.874-.008,1.5,0,3,0,4.493Z" transform="translate(-283.104 -515.508)" fill="#fff" />
                                    <path id="Path_13686" data-name="Path 13686" d="M330.375,523.321H328.52v-.5c0-1.641-.01-3.282.008-4.924,0-.373-.1-.5-.487-.494-1.671.018-3.34,0-5.011.015-.363,0-.488-.113-.459-.471a6.438,6.438,0,0,0,0-.993c-.027-.339.091-.446.437-.444,2.145.014,4.291.007,6.435.007h.931Z" transform="translate(-283.043 -515.508)" fill="#fff" />
                                    <path id="Path_13687" data-name="Path 13687" d="M285,553.636v.573q0,2.354,0,4.708c0,.666,0,.666.644.666H290.9v1.848h-7.769c-.009-.159-.023-.3-.023-.44,0-2.29.007-4.579-.008-6.869,0-.368.077-.524.475-.494C284.032,553.663,284.491,553.636,285,553.636Z" transform="translate(-283.104 -515.449)" fill="#fff" />
                                    <path id="Path_13688" data-name="Path 13688" d="M330.338,561.44H322.58c0-.558-.018-1.1.019-1.642.006-.082.264-.209.408-.21,1.67-.015,3.34-.022,5.01,0,.4.005.518-.1.513-.5-.021-1.67,0-3.339-.015-5.01,0-.333.084-.47.437-.443.387.029.778-.012,1.165.02.1.008.277.169.278.26.01,2.476,0,4.951,0,7.427C330.389,561.363,330.365,561.387,330.338,561.44Z" transform="translate(-283.043 -515.449)" fill="#fff" />
                                    <g id="Group_8415" data-name="Group 8415" transform="translate(4.132 9.187)">
                                        <path id="Path_13689" data-name="Path 13689" d="M313.439,538.12c4.485-.025,8.97-.013,13.456-.013h.515c0,.5.007.961-.008,1.42,0,.061-.105.148-.177.169a1.185,1.185,0,0,1-.328.011q-4.584,0-9.166,0l-6.2,0H301.7q-7.01,0-14.019.007c-.349,0-.481-.084-.451-.444s.007-.731.007-1.165h16.281c.495,0,.5,0,.5-.5,0-2.506-.007-5.011.007-7.517,0-.36-.112-.453-.459-.448q-2.144.026-4.29,0c-.291,0-.381.087-.373.374.018.611,0,1.222.009,1.833.006.239-.075.323-.314.322q-2.163-.012-4.327,0c-.238,0-.319-.088-.318-.321q.008-3.446,0-6.893a2.093,2.093,0,0,1,.039-.26h.359q12.979,0,25.958-.01c.324,0,.411.092.409.414q-.021,3.337,0,6.672c0,.294-.071.407-.386.4q-2.108-.021-4.217,0c-.293,0-.378-.1-.369-.382.018-.587,0-1.174.01-1.76.007-.273-.062-.392-.367-.389-1.467.014-2.934.008-4.4,0-.2,0-.344.006-.343.284.009,2.652-.02,5.533-.018,8.185m-5.015-10.047v.488c0,3.055.005,6.109-.005,9.164,0,.305.091.4.394.394.867-.018,1.736-.021,2.6,0,.334.009.433-.089.431-.429-.012-3.055-.007-6.11-.007-9.165v-.439h8.322c0,.744-.008,1.452.009,2.159,0,.106.12.3.192.3.5.027.995.014,1.518.014.01-.175.023-.308.023-.44,0-1.124-.011-2.248.009-3.372.006-.352-.1-.457-.453-.456q-11.31.014-22.619.009c-.144,0-.287.017-.468.029,0,1.37,0,2.711.006,4.052,0,.058.091.165.141.166.52.012,1.04.008,1.592.008v-2.484Z" transform="translate(-287.23 -524.681)" fill="#36f" />
                                        <path id="Path_13690" data-name="Path 13690" d="M313.147,550.649c0,.491-.007.918,0,1.345,0,.19-.073.268-.256.262-.1,0-.2,0-.292,0-3.556,0-7.112-.005-10.668.006-.356,0-.524-.072-.479-.462a4.326,4.326,0,0,0,0-.805c-.02-.278.09-.356.36-.354,1.392.011,2.785.005,4.178.005h7.153Z" transform="translate(-287.208 -524.641)" fill="#fff" />
                                        <path id="Path_13691" data-name="Path 13691" d="M304.025,543.982h6.583v1.559h-6.583Z" transform="translate(-287.204 -524.651)" fill="#fff" />
                                        <path id="Path_13692" data-name="Path 13692" d="M303.993,548.922c0-.506-.016-.968.016-1.425.005-.071.227-.18.351-.182.953-.015,1.907-.008,2.859-.007,1.015,0,2.029.008,3.044,0,.278,0,.4.076.379.374-.022.315-.012.635,0,.953.005.194-.052.294-.266.294C308.271,548.919,306.169,548.922,303.993,548.922Z" transform="translate(-287.204 -524.646)" fill="#fff" />
                                        <path id="Path_13693" data-name="Path 13693" d="M311.518,538.712" transform="translate(-287.192 -524.659)" fill="#36f" />
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <p>OnlineOCR Converter services that converts images, scans doc images text into editable files by using OCR Technology (Optical Character Recognition).</p>
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                        <h5>Information Links</h5>
                        <ul>
                            <li><Link to="/plans-pricing">Plans/Pricing</Link></li>
                            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                            <li><Link to="/terms-and-conditions">Terms And Conditions</Link></li>
                            <li><Link to="/refund-and-cancellation-policy">Refund And Cancellation Policy</Link></li>
                        </ul>
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                        <h5>Important Links</h5>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/languages">Languages</Link></li>
                            <li><Link to="/contact-us">Contact Us</Link></li>
                            <li><Link to="/faq">Frequently Asked Questions</Link></li>
                        </ul>
                    </Col>
                    <Col lg={3} md={6} sm={12} className="contact">
                        <h5>Get In Touch</h5>
                        <RoomIcon /><p>210, Pramukh Square, <br />Opp. Regenta Resort, <br />Airport Ring Road, <br/> Bhuj – Kutch 370001 </p>
                        <EmailIcon /><a href="mailto:info@arkayapps.com">info@arkayapps.com</a>
                    </Col>
                </Row>
                <hr />
                <div className="copyright">
                    <p>© Copyright 2020-{currentYear} - Online OCR Convertor is a service of Arkay Apps Pvt. Ltd. Website and OCR API <Link to="/privacy-policy">Privacy Policy</Link> - OCR API V2.0</p>
                </div>
            </div>
            <CookieBanner/>
        </section>
    )
}

export default Footer
