import React, { useState } from 'react'
import { LoadingButton } from '@mui/lab';
import { Alert, Chip, CircularProgress, Divider } from '@mui/material';
import { trim } from '../../functions'
import { Col, Row } from 'react-bootstrap';
import { apiPost } from '../../functionsAPI';
import LoginWithGoogle from './LoginWithGoogle';

export default function SignIn(props) {
    const [loading, setLoading] = useState(false)
    const progress = loading ? (<CircularProgress size={30} />) : ('')
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const onRegister = (e) => {
        e.preventDefault()
        setLoading(true)
        setError(false)
        setSuccess(false)
        const data = {
            first_name: trim(firstName),
            last_name: trim(lastName),
            email: trim(email),
            password: trim(password),
        }
        apiPost('/sign-up', data)
            .then((res) => {
                const user = res.data
                localStorage.setItem('oocToken', user.token)
                localStorage.setItem('user', JSON.stringify(user))
                setFirstName('')
                setLastName('')
                setEmail('')
                setPassword('')
                setSuccess('Registered successfully! Please login now.')
                setLoading(false)
            })
            .catch((err) => {
                setError(err)
                setLoading(false)
            });
    }

    return (
        <>
            <div className="card">
                <h2>Create New Account</h2>
                <form onSubmit={onRegister}>
                    <Row>
                        <Col md={6}>
                            <input
                                type="text"
                                required
                                className="form-control"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                placeholder="Enter First Name" />
                        </Col>
                        <Col md={6}>
                            <input
                                type="text"
                                required
                                className="form-control"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                placeholder="Enter Last Name" />
                        </Col>
                        <Col md={6}>
                            <input
                                type="email"
                                required
                                className="form-control"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter Email" />
                        </Col>
                        <Col md={6}>
                            <input
                                type="password"
                                required
                                className="form-control"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter Password" />
                        </Col>
                    </Row>
                    <LoadingButton
                        type='submit'
                        loading={loading}
                        className="mt-4"
                        fullWidth
                        loadingIndicator="Registering..."
                        variant="contained"
                    >
                        Register
                    </LoadingButton>
                    <p className='mt-3 text-center'>
                        {progress}
                    </p>
                </form>
                <Divider className='my-4'>
                    <Chip label="OR" variant="outlined" />
                </Divider>
                <LoginWithGoogle />
                {success ? (
                    <Alert severity="success">{success}</Alert>
                ) : ''}
                {error ? (
                    <Alert severity="error">{error}</Alert>
                ) : ''}
            </div>
        </>
    )
}
