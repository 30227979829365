import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { apiGetURL } from '../functionsAPI'
import selectedPlanContext from "../selectedPlanContext";


const priceInr = {
    symbol: '₹',
    amount: '999',
}

const priceOther = {
    symbol: '$',
    amount: '15',
}

export default function MostPopularPlan(props) {
    const [symbol, setSymbol] = useState('₹')
    const [amount, setAmount] = useState('999')
    const contextPlan = useContext(selectedPlanContext)
    const getCountry = () => {
        apiGetURL('https://ipapi.co/json/')
            .then((res) => {
                const countryCode = res.data.country_code
                if (countryCode === 'IN') {
                    setSymbol(priceInr.symbol)
                    setAmount(priceInr.amount)
                }
                else {
                    setSymbol(priceOther.symbol)
                    setAmount(priceOther.amount)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        getCountry();
        window.scrollTo(0, 0)
        // eslint-disable-next-line
    }, [])


    return (
        <div>
            <div className="type-2">
                <h3>Professional</h3>
                <p>Most Popular</p>
                <div className="price">
                    <span className="currency">{symbol}</span>
                    <span className="amount">{amount}</span>
                </div>
                <p className='validity'>Per Month</p>
                <div className="features">
                    <p>100 Images Per Day</p>
                    <p>20MB File Size</p>
                    <p>High Priority</p>
                    <p>Adfree Pages</p>
                    <p>Prioritized Support</p>
                </div>
                {props.displayButton ? (
                    <Link to="/sign-in?next=subscribe" onClick={()=>contextPlan.update('MostPopular')} className="btn-action">Purchase Now</Link>
                ) : ''}
            </div>
        </div>
    )
}

MostPopularPlan.defaultProps = {
    displayButton: true
}