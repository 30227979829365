import React, { useState } from 'react'
import { trim } from '../functions'
import RoomIcon from '@mui/icons-material/Room';
import EmailIcon from '@mui/icons-material/Email';
import { Alert, CircularProgress, Grid } from '@mui/material';
import contactImage from '../assets/images/contact.svg'
import { LoadingButton } from '@mui/lab';
import { apiPost } from '../functionsAPI';

const   ContactComponent = () => {
    const [loading, setLoading] = useState(false)
    const progress = loading ? (<CircularProgress size={30} />) : ('')

    const [result, setResult] = useState('')
    const [error, setError] = useState(false)

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const onSubmit = (e) => {
        e.preventDefault()
        setResult('')
        setError(false)
        setLoading(true)
        const data = {
            name: trim(name),
            email: trim(email),
            number: "+9100000000",
            message: trim(message),
        }
        apiPost('/send-message', data)
            .then((res) => {
                setResult('Thanks! Message sent successfully')
                setName('')
                setEmail('')
                setMessage('')
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
                setError(err)
                setLoading(false)
            });
    }

    return (
        <>
            <section id='contact' className="container">
                <div className="card">
                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid item xs={12} sm={5}>
                            <div className="text-center">
                                <img src={contactImage} alt="Contact Us" className='img-fluid' />
                            </div>
                            <div className="info">
                                <RoomIcon /><p>210, Pramukh Square, <br />Opp. Regenta Resort, <br />Airport Ring Road, <br /> Bhuj – Kutch 370001 </p>
                                <EmailIcon /><p><a href="mailto:info@arkayapps.com">info@arkayapps.com</a></p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h2>Get In Touch</h2>
                            <p>We are here for you! How can we help you?</p>
                            <form onSubmit={onSubmit}>
                                <input type="text" required className="form-control" value={name} minLength="3" maxLength="50" onChange={(e) => setName(e.target.value)} placeholder="Enter your name" />
                                <input type="email" required className="form-control" value={email} minLength="3" maxLength="50" onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email" />
                                <textarea className="form-control" required rows="5" value={message} placeholder="Enter your message" minLength="5" maxLength="255" onChange={(e) => setMessage(e.target.value)}></textarea>
                                <LoadingButton
                                    type='submit'
                                    loading={loading}
                                    className="mt-4"
                                    fullWidth
                                    loadingIndicator="Sending..."
                                    variant="contained"
                                >
                                    Submit
                                </LoadingButton>
                                <p className='mt-5 text-center'>
                                    {progress}
                                </p>
                            </form>
                            {result ? (
                                <div className="result">
                                    <Alert severity="success">{result}</Alert>
                                </div>
                            ) : ''}
                            {error ? (
                                <Alert severity="error">{error}</Alert>
                            ) : ''}
                        </Grid>
                    </Grid>
                </div>
            </section>
        </>
    )
}

export default ContactComponent