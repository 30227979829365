import React from 'react'
import { Button } from '@mui/material'
import { Link } from 'react-router-dom'

export default function OurApi() {
    return (
        <>
            <section className='section-bg'>
                <div className="container">
                   <div className='d-flex justify-content-between align-items-center'>
                    <div>
                        <h2>Our OCR tool in a REST API</h2>
                        <p>Get our REST API with simple and concise documentation for easy integration <br/> to automate your OCR process</p>
                    </div>
                    <div>
                        <Link to="ocr-rest-api"><Button variant="contained" className="btn-primary-1">Know More</Button></Link>                        
                    </div>
                   </div>
                </div>
            </section>
        </>
    )
}