import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from 'react-router-dom';

const CookieBanner = () => {
const [showCookieBanner, setShowCookieBanner] = useState(false);

useEffect(() => {
    const isConsentAccepted = localStorage.getItem("cookieConsentAccepted");
    if (!isConsentAccepted) {
        setShowCookieBanner(true);
    }
}, []);

const handleOnAccept = () => {
    localStorage.setItem("cookieConsentAccepted", true);
    setShowCookieBanner(false);
};

const cookieStyle = {
    background: '#fff',
     color: '#000', 
     width: '100%',
     padding: '20px',
}

const buttonStyle = {
    color: "#fff", 
    fontSize: "16px", 
    background: "#3366ff", 
    padding: "10px 20px",
    margin: 'unset'
}

const headerStyle = {
    fontSize: "25px",  
    fontWeight: "600", 
    lineHeight: "1.5"
}

const linkStyle = {
    color: "#3366ff", 
    opacity: "1"
}


return (
<>
{showCookieBanner && (
    <CookieConsent
        containerClasses="d-block text-center"
        debug={true}
        style={cookieStyle}
        buttonStyle={buttonStyle}
        buttonText="Accept All"
        onAccept={handleOnAccept}
    >
        <h3 style={headerStyle}>We Use Cookies.</h3>
        <span style={{ fontSize: "16px" }}>This site uses cookies to deliver our services and to ensure you get the best experience. By  continuing to use this site, you constent to our use of cookies and acknowledge that you have read and understand our <Link style={linkStyle} to="/privacy-policy">Privacy Policy</Link>, <Link style={linkStyle} to="/terms-and-conditions">Terms and Conditions</Link></span>
    </CookieConsent>
    )}
</>
);
};

export default CookieBanner;