import axios from 'axios'
import { URL } from './config'

function getHeaders() {
    const token = localStorage.getItem('oocToken')
    const headers = {
        'accept': 'application/json',
        'Content-Type': 'application/json',
        token
    }
    return headers
}

export const apiGet = (apiURL = '/URL_HERE', params = {}) => {
    const url = URL + apiURL
    const headerConfig = {
        headers: getHeaders(),
        params
    }
    return new Promise(async (resolve, reject) => {
        await axios.get(url, headerConfig)
            .then((data) => resolve(data))
            .catch((error) => {
                if (error.response) {
                    reject(error.response.data.detail)
                } else {
                    reject(error.message)
                }
            });
    });
};

export const apiPost = (apiURL = '/URL_HERE', data = {}) => {
    const url = URL + apiURL
    const headerConfig = { headers: getHeaders() }
    return new Promise(async (resolve, reject) => {
        await axios.post(url, data, headerConfig)
            .then((data) => resolve(data))
            .catch((error) => {
                if (error.response) {
                    reject(error.response.data.detail)
                } else {
                    reject(error.message)
                }
            });
    });
};

export const apiPut = (apiURL = '/URL_HERE', data = {}) => {
    const url = URL + apiURL
    const headerConfig = { headers: getHeaders() }
    return new Promise(async (resolve, reject) => {
        await axios.put(url, data, headerConfig)
            .then((data) => resolve(data))
            .catch((error) => {
                if (error.response) {
                    reject(error.response.data.detail)
                } else {
                    reject(error.message)
                }
            });
    });
};

export const apiDelete = (apiURL = '/URL_HERE') => {
    const url = URL + apiURL
    const headerConfig = { headers: getHeaders() }
    return new Promise(async (resolve, reject) => {
        await axios.delete(url, headerConfig)
            .then((data) => resolve(data))
            .catch((error) => {
                if (error.response) {
                    reject(error.response.data.detail)
                } else {
                    reject(error.message)
                }
            });
    });
};

export const apiGetFile = (apiURL = '/URL_HERE', params = {}) => {
    const url = URL + apiURL
    const headerConfig = {
        headers: getHeaders(),
        params,
        responseType: 'blob'
    }
    return new Promise(async (resolve, reject) => {
        await axios.get(url, headerConfig)
            .then((data) => resolve(data))
            .catch((error) => {
                if (error.response) {
                    reject(error.response.data.detail)
                } else {
                    reject(error.message)
                }
            });
    });
};

export const apiGetURL = (apiURL, params = {}) => {
    const url = apiURL

    return new Promise(async (resolve, reject) => {
        await axios.get(url)
            .then((data) => resolve(data))
            .catch((error) => {
                if (error.response) {
                    reject(error.response.data.detail)
                } else {
                    reject(error.message)
                }
            });
    });
};