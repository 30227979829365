import React from 'react'
import CheckIcon from '@mui/icons-material/Check';
import { Col, Container, Row } from 'react-bootstrap'
import AppFeatures from '../assets/images/app-features.svg'

export default function Features() {
    return (
        <div>

            <section>
                <Container>
                    <Row className='features'>
                        <Col md={6} className='my-auto'>
                            <img src={AppFeatures} alt="App Features" className='img-fluid' />
                        </Col>
                        <Col md={6}>
                            <h2>Features</h2>
                            <div className='feature'>
                                <div className="icon">
                                    <CheckIcon />
                                </div>
                                <h3>Unlimited scans</h3>
                            </div>
                            <div className='feature'>
                                <div className="icon">
                                    <CheckIcon />
                                </div>
                                <h3>No registration required</h3>
                            </div>
                            <div className='feature'>
                                <div className="icon">
                                    <CheckIcon />
                                </div>
                                <h3>100+ recognition languages</h3>
                            </div>
                            <div className='feature'>
                                <div className="icon">
                                    <CheckIcon />
                                </div>
                                <h3>Supports poorly scanned and photographed pages</h3>
                            </div>
                            <div className='feature'>
                                <div className="icon">
                                    <CheckIcon />
                                </div>
                                <h3>Multi-language recognition</h3>
                            </div>
                            <div className='feature'>
                                <div className="icon">
                                    <CheckIcon />
                                </div>
                                <h3>Copy to clipboard</h3>
                            </div>
                            <div className='feature'>
                                <div className="icon">
                                    <CheckIcon />
                                </div>
                                <h3>Low-resolution image support.</h3>
                            </div>
                            <div className='feature'>
                                <div className="icon">
                                    <CheckIcon />
                                </div>
                                <h3>Keeps your data safe and secure <br /> (after scan your files will be removed from the server)</h3>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}
