export const menu = [
    {
        label: 'Home',
        path: '/'
    },
    {
        label: 'About',
        path: '/about'
    },
    {
        label: 'Plans/Pricing',
        path: '/plans-pricing'
    },
    {
        label: 'REST API',
        path: '/ocr-rest-api'
    },
    {
        label: 'Languages',
        path: '/languages'
    },
    {
        label: 'FAQ',
        path: '/faq'
    },
    {
        label: 'Contact Us',
        path: '/contact-us'
    }
]