import React, { useEffect } from 'react'
import MetaTags from 'react-meta-tags';
import ContactComponent from '../components/ContactComponent';
import { about } from '../MetaTags'

const About = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <MetaTags>
                <title>{about.title}</title>
                <meta name="description" content={about.description} />
                <link rel="canonical" href={about.canonical} />
            </MetaTags>
            <section className="breadcrumbs">
                <div className="inner">
                    <div className="container">
                        <h1>About Us</h1>
                        <p>Convert your images, scanned doc images text into editable files</p>
                    </div>
                </div>
            </section>
            <section >
                <div >
                    <div className="container">
                        <h2>OnlineOCR Converter</h2>
                    </div>
                </div>
                <div className="container">
                    <p>OnlineOCR Converter services that converts images, scans doc images text into editable files by using OCR Technology 
                        (Optical Character Recognition). Our OCR Services is available daily 15 scans free of use. There is no registration required to 
                        scan documents. Users can use JPG, PNG images for scene text. Users can select any available language.</p>
                </div>
            </section>
            <section className='section-bg'>
                <div >
                    <div className="container">
                        <h2>Supported image formats</h2>
                    </div>
                </div>
                <div className="container">
                    <p>Online OCR Support Following Image formates
                        <ul>
                            <li>JPEG</li>
                            <li>PNG</li>
                        </ul>
                    </p>
                </div>
            </section>
            <ContactComponent />   
        </>
    )
}

export default About