// Core
import React from 'react'
// Library
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
// Components
import Header from './components/Header'
import Footer from './components/Footer'
// Pages
import Homepage from './pages/Homepage'
import About from './pages/About'
import Ocr from './pages/Ocr'
import PlansPricing from './pages/PlansPricing'
import Languages from './pages/Languages'
import ContactUs from './pages/ContactUs'
import OcrRestApi from './pages/OcrRestApi'
import OcrRestApiSingle from './pages/OcrRestApiSingle'
import Faq from './pages/Faq'
import PrivacyPolicy from './pages/PrivacyPolicy'
import TermsAndConditions from './pages/TermsAndConditions'
import RefundAndCancellationPolicy from './pages/RefundAndCancellationPolicy'
import SignIn from './pages/auth/SignIn'
import ForgotPassword from './pages/auth/ForgotPassword'
import EmailVerification from './pages/auth/EmailVerification'
import SelectPlan from './pages/subscription/SelectPlan'
import PaymentSuccess from './pages/subscription/PaymentSuccess'
import Profile from './pages/Profile'
import Error404 from './pages/Error404'
import Documentation from './pages/Documentation'
import SelectedPlanState from './SelectedPlanState'

function App() {
    return (
        <div>
            <SelectedPlanState>
                <Router>
                    <Header />
                    <Switch>
                        <Route exact path='/' component={Homepage} />
                        <Route exact path='/about' component={About} />
                        <Route exact path='/plans-pricing' component={PlansPricing} />
                        <Route exact path='/languages' component={Languages} />
                        <Route exact path='/contact-us' component={ContactUs} />
                        <Route exact path='/ocr-rest-api' component={OcrRestApi} />
                        <Route exact path='/ocr-rest-api-for-:restApiUrl' component={OcrRestApiSingle} />
                        <Route exact path='/faq' component={Faq} />
                        <Route exact path='/privacy-policy' component={PrivacyPolicy} />
                        <Route exact path='/terms-and-conditions' component={TermsAndConditions} />
                        <Route exact path='/refund-and-cancellation-policy' component={RefundAndCancellationPolicy} />
                        <Route exact path='/sign-in' component={SignIn} />
                        <Route exact path='/forgot-password' component={ForgotPassword} />
                        <Route exact path='/profile' component={Profile} />
                        <Route exact path="/verify" component={EmailVerification} />
                        <Route exact path="/subscribe" component={SelectPlan} />
                        <Route exact path="/payment-success" component={PaymentSuccess} />
                        <Route exact path="/docs" component={Documentation} />
                        <Route exact path='/oops' component={Error404} />
                        <Route exact path='/:languageURL' component={Ocr} />
                    </Switch>
                    <Footer />
                </Router>
            </SelectedPlanState>
        </div>
    );
}

export default App;
