import React, { useEffect } from 'react'
import LanguagesList from '../languages.json'
import { Col, Container, Row } from 'react-bootstrap'
import { Button } from '@mui/material'
import { Link } from 'react-router-dom'

export default function Languages(props) {

    useEffect(() => {
        window.scrollTo(0, 0)
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <section className={props.bg ? 'section-bg' : ''}>
                <Container>
                    <div className="text-center">
                        <h2 className='center'>{props.heading}</h2>
                        <p>Instantly convert image documents between 100+ supported languages.</p>
                    </div>
                    <Row className='lang-list'>
                        {LanguagesList.map((language, index) => {
                            return (
                                <Col xl={2} lg={3} md={4} sm={6} xs={6} key={index}>
                                    <div className='language-box'>
                                        <a href={props.restApi ? language.restApiUrl : language.url}><h3>{language.name}</h3></a>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                    {props.viewAllButton ? (
                        <div className="text-center">
                            <Link to="languages"><Button variant="contained" className="btn-primary-2">View All</Button></Link>
                        </div>
                    ) : ''}
                </Container>
            </section>
        </>
    )
}

Languages.defaultProps = {
    heading: 'OCR Converter For More Than 100+ Languages',
    viewAllButton: false,
    bg: false,
    restApi: false
}
